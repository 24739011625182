<template>
  <div class="sususer-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-select v-model="listfilter.enabled" clearable placeholder="请选择状态" size="medium" @change="onSearch">
              <el-option label="启用" value="0"> </el-option>
              <el-option label="禁用" value="1"> </el-option>
            </el-select>

            <el-input placeholder="帐号、姓名搜索" style="width: 300px; margin-left: 10px" size="medium"
              class="form-input-group" v-model="listfilter.realname" @keyup.enter.native="onSearch">
              <el-button slot="append" type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
            </el-input>
          </el-form>
        </div>
        <div class="action-right">
          <el-button type="primary" icon="el-icon-circle-plus-outline" size="medium" @click="onAdd">新增</el-button>
        </div>
      </div>
    </div>

    <el-table class="list-table" ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%"
      :stripe="true" :border="false" v-loading="tableLoading">
      <el-table-column label="编号" prop="Id"></el-table-column>
      <el-table-column label="管理员账号" prop="Account"></el-table-column>
      <el-table-column label="头像">
        <template slot-scope="scope">
          <el-avatar v-if="scope.row.HeadIcon" shape="square" :size="32" :src="scope.row.HeadIcon"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="姓名" prop="RealName"></el-table-column>
      <el-table-column label="性别" prop="Gender">
        <template slot-scope="scope">
          {{ scope.row.Gender == 0 ? "女" : "男" }}
        </template>
      </el-table-column>
      <el-table-column label="角色" prop="RoleName"></el-table-column>
      <el-table-column label="创建时间" prop="CreateTime">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="最后登录时间" prop="UpdateTime">
        <template slot-scope="scope">
          {{ scope.row.UpdateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="EnabledMark">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.EnabledMark == 0" effect="dark" size="small">启用</el-tag>
          <el-tag v-if="scope.row.EnabledMark == 1" type="danger" effect="dark" size="small">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280">
        <template slot-scope="scope">
          <el-button type="success" icon="el-icon-finished" size="mini" @click="onEdit(scope.row)">切换身份</el-button>
          <el-button type="success" icon="el-icon-edit" size="mini" @click="onEdit(scope.row)">修改</el-button>

          <el-dropdown trigger="click" @command="onListActions">
            <el-button type="primary" size="mini" style="margin-left: 10px">
              更多<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{ action: 'login', item: scope.row }">登录信息</el-dropdown-item>
              <el-dropdown-item v-if="scope.row.EnabledMark == 1"
                :command="{ action: 'state', item: scope.row, param: 0 }">启用</el-dropdown-item>
              <el-dropdown-item v-if="scope.row.EnabledMark == 0"
                :command="{ action: 'state', item: scope.row, param: 1 }">禁用</el-dropdown-item>
              <el-dropdown-item :command="{ action: 'del', item: scope.row }">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </template>
      </el-table-column>
    </el-table>

    <pagination background layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
      :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
      @current-change="onPageChange">
    </pagination>

    <el-dialog :title="formtype == 'add' ? '添加用户' : '修改用户'" :visible.sync="dialogFormVisible" width="750px"
      custom-class="geology-dialog" :modal-append-to-body="true" :destroy-on-close="true" :before-close="onFormClose"
      :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form ref="form" class="geology-form" :rules="formRules" :model="form" label-width="120px"
        style="padding-top: 20px">
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="头像" prop="HeadIcon">
              <single-file ref="singleFile" text="选择图片" :auto-upload="true" :show-file-list="true"
                :file-list="formHeadIcon" @success="uploadSuccess"></single-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10" :offset="1">
            <el-form-item label="帐号" prop="Account">
              <el-input v-model="form.Account" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="姓名" prop="RealName">
              <el-input v-model="form.RealName" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="联系电话" prop="MobilePhone">
              <el-input v-model="form.MobilePhone" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="登录密码" prop="UserPassword">
              <el-input type="password" v-model="form.UserPassword" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10" :offset="1">
            <el-form-item label="用户角色" prop="RoleId">
              <el-select v-model="form.RoleId" placeholder="请选择用户角色" size="small" style="width: 100%">
                <el-option v-for="item in roleSelect" :key="item.Id" :label="item.Name" :value="item.Id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="是否启用" prop="EnabledMark">
              <el-switch v-model="form.EnabledMark" :active-value="0" :inactive-value="1">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10" :offset="1">
            <el-form-item label="性别" prop="Gender">
              <el-radio-group v-model="form.Gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="出生日期" prop="Birthday">
              <el-date-picker size="small" type="date" placeholder="选择出生日期" v-model="form.Birthday"
                value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="邮箱" prop="Email">
              <el-input v-model="form.Email" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="微信" prop="WeChat">
              <el-input v-model="form.WeChat" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit">立即保存</el-button>
              <el-button type="primary" plain size="medium" style="margin-left: 10px" @click="onFormClose">取消
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>


    <el-dialog title="设置登录信息" :visible.sync="dialogFormLoginVisible" width="650px" custom-class="geology-dialog"
      :modal-append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-form ref="form" class="geology-form" :model="formLogin" label-width="120px" style="padding-top: 20px">
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="登录编码" prop="LoginCode">
              <el-input v-model="formLogin.LoginCode" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="第一行文字" prop="LineText1">
              <el-input v-model="formLogin.LineText1" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="第二行文字" prop="LineText2">
              <el-input v-model="formLogin.LineText2" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="第三行文字" prop="LineText3">
              <el-input v-model="formLogin.LineText3" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="小标题" prop="SmallTitle">
              <el-input v-model="formLogin.SmallTitle" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="Logo图片" prop="LoginImg">
              <single-file ref="singleFile" text="选择图片" :auto-upload="true" :show-file-list="true"
                :file-list="formLoginLoginImg" @success="formLoginUploadSuccess" @remove="formLoginRemove">
              </single-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onFormLoginSubmit">立即保存</el-button>
              <el-button type="primary" plain size="medium" style="margin-left: 10px"
                @click="dialogFormLoginVisible = false">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSysroleList,
  getSysuserList,
  delSysuser,
  addSysuser,
  editSysuser,
  setSysuserState,
  getLoginDetail,
  updateLoginDetail
} from "@/api/system";
import { SingleFile } from "@/components/upload";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      listfilter: {
        enabled: "",
        realname: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
      dialogFormVisible: false,
      roleSelect: [],
      formtype: "add",
      formHeadIcon: [],
      form: {
        Id: 0,
        Account: "",
        UserPassword: "",
        RealName: "",
        HeadIcon: "",
        Gender: 1,
        Birthday: "",
        MobilePhone: "",
        Email: "",
        WeChat: "",
        RoleId: "",
        EnabledMark: 0,
        SortCode: 0,
        RoleName: "",
      },
      formRules: {
        Account: [{ required: true, trigger: "blur", message: "请输入帐号" }],
        UserPassword: [],
        RealName: [{ required: true, trigger: "blur", message: "请输入名称" }],
        RoleId: [
          { required: true, trigger: "blur", message: "请选择用户角色" },
        ],
        Gender: [{ required: true, trigger: "blur", message: "请选择性别" }],
      },

      dialogFormLoginVisible: false,
      formLogin: {
        LoginCode: "",
        LineText1: "",
        LineText2: "",
        LineText3: "",
        SmallTitle: "",
        LoginImg: "",
      },
      formLoginLoginImg: [],
    };
  },
  components: {
    SingleFile,
  },
  methods: {
    getSysroleList() {
      getSysroleList().then((resdata) => {
        if (resdata.code == 0) {
          this.roleSelect = resdata.data;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    uploadSuccess(file) {
      this.form.HeadIcon = file.path;
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      var listfilter = JSON.parse(JSON.stringify(this.listfilter))
      listfilter.enabled = listfilter.enabled == '' ? -1 : listfilter.enabled;
      getSysuserList(
        this.page.pageindex,
        this.page.pagesize,
        listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delSysuser(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => { });
    },
    onEditState(item, state) {
      setSysuserState(item.Id, state).then(res => {
        if (res.code == 0) {
          item.EnabledMark = state;
        }
      })
    },
    onAdd() {
      this.form = {
        Id: 0,
        Account: "",
        UserPassword: "",
        RealName: "",
        HeadIcon: "",
        Gender: 1,
        Birthday: "",
        MobilePhone: "",
        Email: "",
        WeChat: "",
        RoleId: "",
        EnabledMark: 0,
        SortCode: 0,
        RoleName: "",
      };
      this.formRules.UserPassword = [
        { required: true, trigger: "blur", message: "请输入密码" },
      ];
      this.formtype = "add";
      this.dialogFormVisible = true;
    },
    onEdit(item) {
      this.form = item;
      this.form.UserPassword = "";
      this.formRules.UserPassword = [];
      this.formtype = "edit";
      this.formHeadIcon = this.form.HeadIcon ? [{
        url: this.form.HeadIcon
      }] : [];
      this.dialogFormVisible = true;
    },
    onFormClose() {
      this.getListData();
      this.formHeadIcon = [];
      this.dialogFormVisible = false;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formtype != "edit") {
            addSysuser(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.onFormClose();
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editSysuser(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.onFormClose();
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
    onShowEditLogin(item) {
      this.dialogFormLoginVisible = true;
      getLoginDetail(item.Id).then(res => {
        if (res.code == 0) {
          this.formLogin = res.data;
          this.formLoginLoginImg = res.data.LoginImg ? [{
            url: res.data.LoginImg
          }] : [];
        }
      })
    },
    formLoginUploadSuccess(file) {
      this.formLogin.LoginImg = file.path;
    },
    formLoginRemove() {
      this.formLogin.LoginImg = '';
      this.formLoginLoginImg = [];
    },
    onFormLoginSubmit() {
      updateLoginDetail(this.formLogin).then((res) => {
        if (res.code == 0) {
          this.$message.success("保存成功!");
          this.dialogFormLoginVisible = false;
        } else {
          this.$message.error("保存失败!" + res.msg);
        }
      })
        .catch((errmsg) => {
          this.$message.error("保存失败!" + errmsg);
        });

    },
    onListActions(data) {
      switch (data.action) {
        case "login":
          this.onShowEditLogin(data.item);
          break;
        case "state":
          this.onEditState(data.item, data.param);
          break;
        case "del":
          this.onListDel(data.item);
          break;
        default:
          break;
      }
    },
  },
  created() {
    this.getSysroleList();
    this.getListData();
  },
};
</script>