<template>
  <div class="multi-file-container">
    <el-upload ref="upload" :headers="dataHeader" :action="uploadurl" :auto-upload="autoUpload"
      :show-file-list="showFileList" :file-list="fileList" :list-type="listType" :on-change="onChange"
      :on-exceed="onExceed" :on-success="onSuccess" :on-error="onError" :on-remove="onRemove" :limit="limit"
      :multiple="multiple">
      <el-button size="small" type="primary" v-if="listType != 'picture-card'">{{ text }}</el-button>
      <i v-else slot="default" class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>
<script>
export default {
  data() {
    return {
      change: false,
      dataHeader: {
        auth: this.$store.getters.token,
      },
      uploadFileList: [],
    };
  },
  computed: {
    uploadurl: function () {
      return (
        this.$extconfig.getConfig("apibase", "/") + "/api/Upload/ExportFile"
      );
    },
  },
  props: {
    text: {
      type: String,
      default: "选择文件",
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    fileList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showFileList: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "picture",
    },
    limit: Number,
    multiple: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    onSuccess(response) {
      if (response.code == 0) {
        this.$emit("success", response.data);
      } else {
        this.$message.error("上传文件失败");
      }
    },
    onChange(file, fileList) {
      this.uploadFileList = fileList;
      this.$emit("change", file, fileList);

      this.onComplete(fileList);
    },
    onRemove(file, fileList) {
      this.uploadFileList = fileList;
      this.$emit("remove", file, fileList);
      this.onComplete(fileList);
    },
    onExceed(file, fileList) {
      this.$message.error("超过最大上传数量");
    },
    onError() {
      this.$message.error("上传文件失败");
    },
    onComplete(uploadList) {
      var iscomplete = true;
      var filelist = [];
      uploadList.every((item) => {
        if (item.status && item.status != "success") {
          iscomplete = false;
          return false;
        } else {
          var url = item.url;
          if (item.response) {
            url = item.response.data.path
          }
          filelist.push({
            path: url
          })
          return true;
        }
      });
      if (iscomplete) {
        this.$emit("complete", filelist);
      }
    },
    submit() {
      var change = false;
      this.uploadFileList.every((item) => {
        if (item.status && item.status != "success") {
          change = true;
          return false;
        } else {
          return true;
        }
      });
      if (change) {
        this.$refs.upload.submit();
      } else {
        this.onComplete(this.uploadFileList);
      }
    },
  },
  created() {
    this.fileList.forEach((element) => {
      this.uploadFileList.push(element);
    });
  },
};
</script>
<style lang="scss"  scoped>
.multi-file-container {
  ::v-deep {
    .el-upload-list--picture-card .el-upload-list__item {
      width: 120px;
      height: 120px;
    }

    .el-upload--picture-card {
      width: 120px;
      height: 120px;
      line-height: 120px;

    }
  }

}
</style>